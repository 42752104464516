import React, { useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamation, faCheck, faUpRightAndDownLeftFromCenter, faDownLeftAndUpRightToCenter, faUpload, faDownload, faCopy, faPaste  } from "@fortawesome/free-solid-svg-icons"
import AceEditor from 'react-ace'
import 'brace/mode/json'
import 'brace/theme/ambiance'
import style from './JsonEditor.module.css'
import clsx from "clsx"
import { Tooltip } from '@mui/material'

/**
 * JSON editor that works with json-object-values
 * - user formatting *not* retained
 * - changes are only emitted if the JSON is valid
 */
export function JsonEditor(props: {
  name?: string,
  exportFileName?: string,
  value: unknown,
  onValueChange: (str: unknown) => void
}) {
  var valueAsString =   JSON.stringify(props.value, null, 4)
  const [ draftValue, setDraftValue ] = useState(valueAsString)

  const onChange = (valueString: string) => {
    setDraftValue(valueString)
    try {
      const value = JSON.parse(valueString)
      props.onValueChange(value)
    } catch (e) {
      // ignore
    }
  }

  return (
    <JsonTextEditor name={props.name}
                    exportFileName={props.exportFileName}
                    value={draftValue}
                    onValueChange={onChange} />
  )
}

/**
 * JSON editor that works for json-strings
 * - user formatting retained
 * - changes are emitted whether the JSON is valid or not
 */
export function JsonTextEditor(props: {
  name?: string,
  exportFileName?: string,
  value: string,
  onValueChange?: (str: string) => void
  readonly?: boolean
}) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [ expanded, setExpanded ] = useState(false)
  const [ validationStatus, setValidationStatus ] = useState<string | "ok" | null>(null)

  const checkJson = () => {
    try {
      JSON.parse(props.value)
      setValidationStatus("ok")
    } catch (e) {
      setValidationStatus(String(e))
    }
  }

  const formatJson = () => {
    try {
      const formatted = JSON.stringify(JSON.parse(props.value), null, 4)
      props.onValueChange?.(formatted)
    } catch (e) {
    }
  }

  const exportValueAsJson = () => {
    const blob = new Blob([props.value], { type: 'application/json' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = props.exportFileName || `${props.name || 'data'}.json`
    a.click()
    URL.revokeObjectURL(url)
  }

  const importValueFromJson = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const text = e.target?.result
        if (text) {
          props.onValueChange?.(text as string)
        }
      }
      reader.readAsText(file)
    }
  }

  const copyValueToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(props.value)
    } catch (err) {
      console.error(err)
      alert('Failed to copy')
    }
  }

  const pasteValueFromClipboard = async () => {
    try {
      const text = await navigator.clipboard.readText()
      props.onValueChange?.(text)
    } catch (err) {
      console.error(err)
      alert('Failed to paste')
    }
  }

  return (
    <div className={style.wrapper}>
      <AceEditor
        key={String(expanded)}
        name={props.name}
        mode="json"
        theme="ambiance"
        fontSize="12pt"
        style={{
          width: '100%',
          height: expanded ? '80vh' : '120px',
          fontFamily: 'Consolas, Inconsolata, Monaco, "Lucida Console", monospace',
          fontWeight: 500
        }}
        value={props.value}
        readOnly={props.readonly}
        onChange={value => {
          if (props.readonly) return
          setValidationStatus(null)
          props.onValueChange?.(value)
        }}
      />
      <div className={style.bar}>
        {!props.readonly && (
          <Tooltip title="Format">
            <button className="px-1.5 py-px" type="button" onClick={() => checkJson()}>
              check
            </button>
          </Tooltip>
        )}
        {validationStatus && (
          <div className={clsx(style["json-status"], validationStatus !== "ok" ? style.error : style.ok)}>
            <FontAwesomeIcon icon={validationStatus === "ok" ? faCheck : faExclamation} />
            {validationStatus === "ok" ? "Valid JSON" : String(validationStatus) }
          </div>
        )}
        <div className={style.separator} />
        {!props.readonly && (
          <Tooltip title="Format">
            <button className="px-1.5 py-px" type="button" onClick={() => formatJson()}>
              <b>{`{ }`}</b>
            </button>
          </Tooltip>
        )}
        <Tooltip title="Save as .json">
          <button className="px-1.5 py-px" type="button" onClick={() => exportValueAsJson()}>
            <FontAwesomeIcon icon={faDownload} />
          </button>
        </Tooltip>
        {!props.readonly && (
          <Tooltip title="Upload .json">
            <button className="px-1.5 py-px" type="button" onClick={() => inputRef.current?.click?.()}>
              <input
                ref={inputRef}
                type="file"
                accept=".json"
                style={{ display: "none" }}
                onChange={importValueFromJson}
              />
              <FontAwesomeIcon icon={faUpload} />
            </button>
          </Tooltip>
        )}
        <Tooltip title="Copy to clipboard">
          <button className="px-1.5 py-px" type="button" onClick={() => copyValueToClipboard()}>
            <FontAwesomeIcon icon={faCopy} />
          </button>
        </Tooltip>
        {!props.readonly && (
          <Tooltip title="Paste from clipboard">
            <button className="px-1.5 py-px" type="button" onClick={() => pasteValueFromClipboard()}>
              <FontAwesomeIcon icon={faPaste} />
            </button>
          </Tooltip>
        )}
        <Tooltip
          title={expanded ? "Collapse" : "Expand"}
          onClick={() => setExpanded(prevExpanded => !prevExpanded)}>
          <button className="px-1.5 py-px" type="button">
            <FontAwesomeIcon icon={expanded
              ? faDownLeftAndUpRightToCenter
              : faUpRightAndDownLeftFromCenter} />
          </button>
        </Tooltip>
      </div>
    </div>
  );
}