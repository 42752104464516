import { Parser } from 'sparqljs'
import { TEMPLATE_PLACEHOLDER_REGEX } from './resolve-template-string'

export function restructureResponse(data) {
  let items = (data && data.results.bindings) || []
  items = items.map((item) => {
    let res = {}
    Object.keys(item).forEach((key) => {
      res[key] = item[key].value
    })
    return res
  })

  return items
}

export function aggregateResultsByFirstParameter(restructuredData, id) {
  let items = restructuredData || []
  var id_item = {}
  for (var n in items) {
    var item = items[n]
    var gitem = id_item[item[id]]
    if (gitem == null) {
      gitem = item
      id_item[item[id]] = gitem
    } else {
      // aggregeren maar
      for (var key in item) {
        gitem[key] = item[key]
      }
    }
  }

  return Object.values(id_item)
}

window.top.downloadObjectAsJson = function (exportObj, exportName) {
  var dataStr =
    'data:text/json;charset=utf-8,' +
    encodeURIComponent(JSON.stringify(exportObj))
  var downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', dataStr)
  downloadAnchorNode.setAttribute('download', exportName + '.json')
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}
// window.top.saveCache=function()
// {
//   var exportName="spcache.js";
//   console.log("bewerk de download: json==>js. replace \  '  . begin/eind quotes");
//   var content ="document.cache={"+JSON.stringify(document.cache).substr(1).slice(0, -1)+"};";
//   var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(content);
//   var downloadAnchorNode = document.createElement('a');
//   downloadAnchorNode.setAttribute("href",     dataStr);
//   downloadAnchorNode.setAttribute("download", exportName );
//   document.body.appendChild(downloadAnchorNode); // required for firefox
//   downloadAnchorNode.click();
//   downloadAnchorNode.remove();

// }
window.top.downloadConfig = function () {
  window.top.downloadObjectAsJson(window.top.data, 'config')
}


export function generateEmptyResponse(sparql) {
  var sp = new Parser()
  var parsed = null
  try {
    if (sparql == null) {
      sparql = 'select ?a ?aURI ?c where {?a ?aURI ?c}'
    }
    var sparql2 = sparql
      .replace(new RegExp('<{{', 'g'), '<http://')
      .replace(new RegExp('}}>', 'g'), '.nl>')
    parsed = sp.parse(sparql2)
  } catch (eee) {
    sparql2 = 'select ?a ?aURI ?c where {?a ?aURI ?c}'
    parsed = sp.parse(sparql2)
  }

  var vars = parsed.variables

  var empty = { head: { vars: [] } }
  empty.results = { bindings: [] }
  var bindings = empty.results.bindings
  var record = {}
  bindings.push(record)
  for (var n in vars) {
    if (Array.isArray(vars[n])) {
      var v = vars[n].slice(1)
    } else {
      v = vars[n].variable
      if (v == null) {
        v = vars[n]
      }
      v = v.replace('?', '')
    }
    empty.head.vars.push(v)
    if (v.endsWith('URI')) {
      record[v] = {
        type: 'uri',
        value: 'http://www.example.org/5c3c96ea1d83d8396b87bc0b',
      }
    } else {
      record[v] = { type: 'literal', value: '' }
    }
  }

  return empty
}

window.top.generateEmptyResponse = function () {
  generateEmptyResponse(null)
}

export function parseTextForVariables(query, props) {
  if (!query) {
    return
  }
  //const regex = /{{\S*}}/g;
  let subscribeProps = query.match(TEMPLATE_PLACEHOLDER_REGEX)

  if (!subscribeProps) {
    return query
  }

  subscribeProps = subscribeProps.map((f) => f.replace(/[{}]/g, ''))
  subscribeProps = [...new Set(subscribeProps)] // remove duplicates

  for (var i = 0; i < subscribeProps.length; i++) {
    let subscribeProp = subscribeProps[i]
    let subscribeValue = props.pubsub[subscribeProp]
    if (subscribeValue == null) {
      console.log('found empty property', subscribeProp)
    }

    if (subscribeProp.includes('||')) {
      let subscribeProp2 = subscribeProp.split('||')[0]
      subscribeValue = props.pubsub[subscribeProp2]
      if (subscribeValue === 'http://www.buildingbits.nl/reset') {
        subscribeValue = null
      }

      //  console.log(subscribeProp,subscribeValue,subscribeProp.split(":")[1]);
      if (subscribeValue == null) {
        var prop = subscribeProp.split('||')[1].split('||')[0].split('::')[0]
        subscribeValue = props.pubsub[prop]
        if (subscribeValue === 'http://www.buildingbits.nl/reset') {
          subscribeValue = null
        }
      }
    }
    if (subscribeProp.includes('::') && subscribeValue == null) {
      let subscribeProp2 = subscribeProp.split('::')[0]
      subscribeValue = props.pubsub[subscribeProp2]
      if (subscribeValue === 'http://www.buildingbits.nl/reset') {
        subscribeValue = null
      }
      // console.log(subscribeProp,subscribeValue,subscribeProp.split(":")[1]);
      if (subscribeValue == null) {
        subscribeValue = subscribeProp.split('::')[1]
      }
    }

    const negationPattern = /^!(.+)$/
    const negationMatch = subscribeProp.match(negationPattern)
    if (negationMatch) {
      const negatedSubscribeProp = negationMatch[1]
      const value = props.pubsub[negatedSubscribeProp]
      const booleanValue = String(value).toLowerCase() === 'true'
      subscribeValue = !booleanValue
    }

    //   console.log(subscribeProp,subscribeValue);
    subscribeProp = subscribeProp.replaceAll('||', '\\|\\|')
    let regex2 = new RegExp(`{{${subscribeProp}}}`, 'g')

    try {
      query = query.replace(regex2, subscribeValue)
      // console.log("results ",query);
    } catch (e) {
      console.log(
        'ERROR ',
        query,
        subscribeValue,
        subscribeProps,
        this.props.pubsub[subscribeProp]
      )
    }
  }
  return query
}
