export const sideMenuConfigs = [
  {
    name: 'query',
    type: 'yasgui',
    label:
      'Query to fill tree. First item should be the object label, the second the object URI, the third the parent URI. optional you can use icon, icon2 and icon3 variables for fa-fa icons ()',
  },
  {
    name: 'tabs',
    type: 'array',
    label: 'Tabs',
  },
  {
    name: 'tabSelectionSubscription',
    type: 'text',
    label: 'Variabele to listen to for tab selection',
  },
  {
    name: 'hideTabs',
    type: 'text',
    label:
      'Variabele to listen to for tab visualisation. Use tab names to hide the tabs',
  },
  {
    name: 'disableHiddenTabs',
    type: 'boolean',
    label:
      'show the hidden tabs but disable them and use a lock icon'
  },
  
  {
    name: 'icons',
    type: 'array',
    label:
      'fa icon names for each tab such as box,cogs,coffee,envelope,hand-point-left,arrow-left,cube,database,gavel,gift,stop,',
  },
  {
    name: 'headertext',
    type: 'text',
    label: 'text for the header when expanded',
  },
  {
    name: 'lazyLoadingPages',
    type: 'boolean',
    label: 'does not load the tabs/pages in advance. Also returning to a page results in a reload',
  },
  {
    name: 'footertext',
    type: 'text',
    label: 'text for the footer',
  },
  {
    name: 'backgroundimage',
    type: 'text',
    label: 'background image',
  },
  {
    name: 'customStyling',
    type: 'boolean',
    label: 'Enable Custom Styling',
  },
  {
    name: 'sidemenuBackgroundColor',
    type: 'text',
    label: 'The background Color in Hex format. e.g. #000000',
  },
  {
    name: 'sidemenuTabColor',
    type: 'text',
    label: 'The background color of the tab in Hex format. e.g. #000000',
  },
  {
    name: 'sidemenuTabIconColor',
    type: 'text',
    label: 'The background color of the tab icon of the tab in Hex format. e.g. #000000',
  },
  {
    name: 'sidemenuTabTextColor',
    type: 'text',
    label: 'The text color of the tab in Hex format. e.g. #000000',
  },
  {
    name: 'sidemenuTabHoverColor',
    type: 'text',
    label: 'The background color of the tab on mouse hovering in Hex format. e.g. #000000',
  },
  {
    name: 'sidemenuTabIconHoverColor',
    type: 'text',
    label: 'The background color of the tab icon on mouse hovering in Hex format. e.g. #000000',
  },
  {
    name: 'sidemenuTabTextHoverColor',
    type: 'text',
    label: 'The text color of the tab on mouse hovering in Hex format. e.g. #000000',
  },
  {
    name: 'startCollapsed',
    type: 'boolean',
    label: 'start collapsed',
  },
  {
    name: 'onlyCollapsed',
    type: 'boolean',
    label: 'only collapsed',
  },
  {
    name: 'rightside',
    type: 'boolean',
    label: 'show menu on the rightside',
  },
  {
    name: 'className',
    type: 'text',
    label: 'css classNames',
  },
  {
    name: 'areas',
    type: 'json',
    // label: 'Column widths or row heights (separated by commas). Value can be a number (which will be set as flex values/verhoudingswaarden) or a px/% value',
    label: 'area definitions',
  },
] as const
