import React from 'react'
import { restructureResponse } from '../../helpers/sparql'
import styles from './TableCells.module.css'

class TableCells extends React.Component {
  static getDescription() {
    return {
      name: 'TableCells',
      component: TableCells,
      label: 'TableCells widget',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'Query to fill table. Use ?header ?value ?row ?col to generate a matrix table .',
        },
        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Variabele in which tree item URI is published, when a link in the table  is clicked.',
        },
        {
          name: 'isFirstItemBold',
          type: 'boolean',
          label: 'First item bold',
        },
        {
          name: 'title',
          type: 'text',
          label: 'Titel boven de tabel',
        },
        {
          name: 'onClick',
          type: 'javascript',
          label: 'Code that runs before link click event.',
        },
      ],
    }
  }

  createStateValues(items) {
    // not used
    let res = {}
    for (var i = 0; i < items.length; i++) {
      let item = items[i]
      Object.keys(item).forEach((key) => {
        if (key.endsWith('URI')) return
        let obj = { value: item[key] }
        if (item[key + 'URI']) obj.link = item[key + 'URI']
        if (!res[key]) {
          res[key] = obj
        } else if (Array.isArray(res[key])) {
          if (!res[key].filter((re) => re.value === obj.value).length) {
            res[key].push(obj)
          }
        } else if (res[key].value !== item[key]) {
          res[key] = [res[key], obj]
        }
      })
    }

    Object.keys(res).forEach((key) => {
      if (Array.isArray(res[key])) {
        res[key].sort((a, b) => a.value.localeCompare(b.value))
      }
    })

    return res
  }

  render() {
    //console.log("table 2 render function ");
    let data = this.props.data
    //  console.log(data);
    if (!data || !data.results || data.results.bindings.length === 0) {
      return null
    }

    let items = restructureResponse(data)

    if (!items.length) return null

    var rowsHash = {}
    var rows = []
    var headersHash = {}
    var headers = []
    var n = 0
    for (var nitem in items) {
      var item = items[nitem]
      n++
      if (headersHash[item.header] == null) {
        headersHash[item.header] = n
        headers.push(item.header)
      } // collect unique headers;
      var row = rowsHash[item.row]
      if (row == null) {
        row = { row: item.row }
        rowsHash[item.row] = row
        rows.push(row)
      }
      row[item.header] = item.value
    }

    console.log(rows)

    return (
      <div
        className={
          styles.main +
          (this.props.isFirstItemBold ? ' ' + styles.firstItemBold : '')
        }
      >
        {this.props.title && (
          <div className={styles.title}>{this.props.title}</div>
        )}
        <table>
          {
            <thead>
              <tr>
                {headers.map((headVar, index) => (
                  <th key={'header' + index}>{headVar}</th>
                ))}
              </tr>
            </thead>
          }

          <tbody>
            {rows.map((row, rindex) => (
              <tr key={'row' + rindex}>
                {headers.map((header, hindex) => (
                  <td key={'td' + hindex}>{row[header]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default TableCells
