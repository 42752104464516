import {RefinementCtx, z} from 'zod'
import {v4 as uuidv4} from 'uuid'
import { getPlaceholders } from '../../../helpers/resolve-template-string'

export type TablePlusPlusCustomConfig = z.infer<typeof stateSchema>
export type TableppColumnConfig = z.infer<typeof columnConfigSchema>
export type TableppDataColumnConfig = z.infer<typeof dataColumnConfigSchema>
export type TableppActionColumnConfig = z.infer<typeof actionColumnConfigSchema>
export type TableppAction = z.infer<typeof actionSchema>
export type RowEditAction = z.infer<typeof rowEditorActionSchema>
export type ColumnConfig = TablePlusPlusCustomConfig['columnConfigs'][number]
export type EditableColumnConfig = z.infer<typeof editableColumnSchema>

export const DEFAULT_TABLEPP_CUSTOM_CONFIG: TablePlusPlusCustomConfig = {
  columnConfigs: [],
}

const idSchema = z.string().nonempty()

const editableColumnStringSchema = z.object({
  componentType: z.union([z.literal('text'),
                          z.literal('textarea')]).default('text'),
})

export const toObjectTypeLabel: Record<EditableColumnConfig['objectType'], string> = {
  'http://www.w3.org/2001/XMLSchema#string': 'xsd:string',
} as const

export const toComponentTypeLabel: Record<EditableColumnConfig['componentType'], string> = {
  'text': 'single-line textfield',
  'textarea': 'multi-line textfield',
} as const 

export const toWhenEmptyFieldOptionLabel: Record<EditableColumnConfig['whenEmptyField'], string> = {
  'disallow': 'disallow empty field',
  'remove-triple': 'remove triple if empty field',
  'update-as-zero-value': 'update as empty string',
} as const


const editableColumnSchema = z.intersection(
    z.object({enabled: z.boolean(),
              graph: z.optional(z.string()),
              subject: z.string(),
              predicate: z.string(),
              objectType: z.literal('http://www.w3.org/2001/XMLSchema#string'),
              whenEmptyField: z.union([z.literal('disallow'),
                                       z.literal('update-as-zero-value'),
                                       z.literal('remove-triple')]).default('disallow'),
             }),
    editableColumnStringSchema,
)

const requiredDataColumnSchema = z.object({
  id: idSchema,
  label: z.string(),
  filterable: z.boolean(),
  sortable: z.boolean(),
  width: z.string(),
})

const optionalDataColumnSchema = z.object({
  publishTopic: z.optional(z.string()),
  editable: z.optional(editableColumnSchema),
})

export const dataColumnConfigSchema = z.intersection(requiredDataColumnSchema, optionalDataColumnSchema)

const startRuleActionSchema = z.object({
  type: z.literal('start-rule'),
  startRule: z.string(),
  parameters: z.optional(z.record(z.string(), z.string())),
  timestampVariable: z.optional(z.string()),
})

const publishAppVarSchema = z.object({
  type: z.literal('publish'),
  topic: z.string(),
  value: z.string(),
})

const rowEditorActionSchema = z.object({
  type: z.union([
    z.literal('start-row-edit'),
    z.literal('cancel-row-edit'),
    z.literal('send-row-edit-request'),
    z.literal('send-row-delete-request'),
  ]),
  timestampVariable: z.optional(z.string()),
})

const notificationSchema = z.object({
  onSuccess: z.optional(z.string()),
  onFailure: z.optional(z.string()),
})

const actionSchema = z.intersection(
  z.union([startRuleActionSchema, publishAppVarSchema, rowEditorActionSchema]),
  z.object({notification: z.optional(notificationSchema)}),
)

const confirmationSchema = z.object({
  message: z.optional(z.string()),
  yesIcon: z.optional(z.string()),
  yesColor: z.optional(z.string()),
  noIcon: z.optional(z.string()),
  noColor: z.optional(z.string()),
})

export const actionColumnConfigSchema = z.object({
  id: idSchema,
  label: z.string(),
  width: z.string(),
  buttons: z.array(z.object({
    icon: z.optional(z.string()),
    label: z.optional(z.string()),
    actions: z.optional(z.array(actionSchema)),
    buttons: z.optional(z.array(z.object({
      label: z.string(),
      icon: z.string(),
      actions: z.array(actionSchema),
      confirmation: z.optional(confirmationSchema),
      hidden: z.optional(z.union([z.boolean(), z.string()])),
    }))),
    confirmation: z.optional(confirmationSchema),
    hidden: z.optional(z.union([z.boolean(), z.string()])),
  })),
})

const columnConfigSchema = z.union([
  dataColumnConfigSchema,
  actionColumnConfigSchema,
])

const rowEditorSchema = z.object({
  enabled: z.boolean().default(true),
  graph: z.string(),
})

export const stateSchema = z.object({
  columnConfigs: z.array(columnConfigSchema),
  rowEditor: z.optional(rowEditorSchema),
})

export function parseColumn(column: unknown) {
  return {
    dataColumn: dataColumnConfigSchema.safeParse(column),
    actionColumn: actionColumnConfigSchema.safeParse(column),
  }
}

export function createDefaultActionColumnConfig(): ColumnConfig {
  return {
    id: uuidv4(),
    label: 'Actions',
    width: 'auto',
    buttons: [
      {
        icon: 'fa-star',
        label: 'Run a rule!',
        actions: [defaultAction('start-rule')]
      },
      {
        icon: 'radix-component-instance',
        label: 'Pubsub something!',
        actions: [defaultAction('publish')],
      },
      {
        label: 'edit this row',
        icon: 'radix-pencil-1',
        actions: [defaultAction('start-row-edit')],
      },
      {
        label: 'send edit request',
        icon: 'radix-check',
        actions: [defaultAction('send-row-edit-request')],
        confirmation: {
          message: 'Are you sure you want to finalise this edit?',
          yesIcon: '',
          yesColor: '',
          noIcon: '',
          noColor: '',
        },
      },
      {
        label: 'cancel editing',
        icon: 'radix-cross-2',
        actions: [defaultAction('cancel-row-edit')],
      },
      {
        label: 'delete this row',
        icon: 'radix-trash',
        actions: [defaultAction('send-row-delete-request')],
        confirmation: {
          message: 'Are you sure you want to delete this row?',
          yesIcon: '',
          yesColor: '',
          noIcon: '',
          noColor: '',
        },
      },
    ],
  }
}

function defaultAction(type: TableppAction['type']): TableppAction {
  switch (type) {
    case 'start-rule': return {
      type,
      notification: fallbackMessage[type],
      startRule: 'my-rule-tag',
      parameters: { 'myRuleParam': '{{myRuleParamValue}}' },
      timestampVariable: 'myTimestampAfterRuleCompletes',
    }
    case 'publish': return {
      type,
      notification: fallbackMessage[type],
      topic: 'myTopic',
      value: '{{columnVariableOrPubsubVariable}}',
    }
    case 'start-row-edit': return {
      type,
      notification: fallbackMessage[type],
    }
    case 'cancel-row-edit': return {
      type,
      notification: fallbackMessage[type],
    }
    case 'send-row-edit-request': return {
      type,
      notification: fallbackMessage[type],
      timestampVariable: 'timestampRowEditUpdate',
    }
    case 'send-row-delete-request': return {
      type,
      notification: fallbackMessage[type],
      timestampVariable: 'timestampRowDeleteUpdate',
    }
  }
}

export function getDefaultEditableColumnConfig(): EditableColumnConfig {
  return {
    enabled: true,
    subject: '',
    predicate: '',
    objectType: 'http://www.w3.org/2001/XMLSchema#string',
    componentType: 'text',
    whenEmptyField: 'disallow',
  }
}

export function getDefaultRowEditorConfig(): z.infer<typeof rowEditorSchema> {
  return {
    enabled: false,
    graph: '',
  }
}

export function getTimestampVars(state: TablePlusPlusCustomConfig) {
  const timestampVars: Set<string> = new Set()
  const pushTimestampActionVars = (actions: TableppAction[]) => {
      for (const action of actions) {
        if (action.type === 'send-row-delete-request' || action.type === 'send-row-edit-request')
          if ('timestampVariable' in action && action.timestampVariable)
            for (const timestampVar of action.timestampVariable.split(','))
              timestampVars.add(timestampVar)
      }
  }

  for (const column of state.columnConfigs) {
    if ('buttons' in column) for (const button of column.buttons) {
      if (button.actions) pushTimestampActionVars(button.actions)

      if (button.buttons) for (const btn of button.buttons) {
        if (btn.actions) pushTimestampActionVars(btn.actions)
      }
    }
  }

  return timestampVars
}

export function getActionTypes(state: TablePlusPlusCustomConfig) {
  const actionTypes: Set<string> = new Set()
  const collectActionTypes = (actions: TableppAction[]) => {
      for (const action of actions) actionTypes.add(action.type)
  }

  for (const column of state.columnConfigs) {
    if ('buttons' in column) for (const button of column.buttons) {
      if (button.actions) collectActionTypes(button.actions)

      if (button.buttons) for (const btn of button.buttons) {
        if (btn.actions) collectActionTypes(btn.actions)
      }
    }
  }

  return actionTypes
}

export const CUSTOM_COMBINATION_PATH = "custom combination"
export function refineRowEditorCombinations(
  config: TablePlusPlusCustomConfig,
  ctx: RefinementCtx,
  currentQuery: string,
): void {

      if (config.rowEditor?.enabled) {
        const missingTimestampVars = getTimestampVars(config)
        const placeholders = getPlaceholders(currentQuery)
        for (const placeholder of placeholders) missingTimestampVars.delete(placeholder)

        if (missingTimestampVars.size) ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [CUSTOM_COMBINATION_PATH],
          message: `sparql query is missing the following timestamp variables: ${[...missingTimestampVars].join(', ')}`,
        })

        const rowEditActionTypes = new Set<RowEditAction['type']>()
          .add('start-row-edit')
          .add('cancel-row-edit')
          .add('send-row-edit-request')
          .add('send-row-delete-request')
        const actionTypes = getActionTypes(config)
        const missingActionTypes = rowEditActionTypes.difference(actionTypes)
        if (missingActionTypes.size) ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [CUSTOM_COMBINATION_PATH],
          message: `missing action-buttons with the following action-types: ${[...missingActionTypes].join(', ')}`,
        })
      }

      if (!config.rowEditor?.enabled) {
        const rowEditActionTypes = new Set<RowEditAction['type']>()
          .add('start-row-edit')
          .add('cancel-row-edit')
          .add('send-row-edit-request')
          .add('send-row-delete-request')
        const actionTypes = getActionTypes(config)
        const disallowedActionTypes = rowEditActionTypes.intersection(actionTypes)
        if (disallowedActionTypes.size) ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [CUSTOM_COMBINATION_PATH],
          message: `actions of the following types are not allowed without row-editing enabled: ${[...disallowedActionTypes].join(', ')}`,
        })
      }

}

export function isDataColumn(column: unknown): column is TableppDataColumnConfig {
  const result = requiredDataColumnSchema.safeParse(column)
  return result.success
}

export const fallbackMessage = {
  "publish": {onSuccess: "succesfully published app variable",
              onFailure: "failed to publishe app variable",},
  "start-rule": {onSuccess: "succesfully ran rule(-chain) completely",
                 onFailure: "failed to run rule(-chain) completely",},
  "send-row-delete-request": {onSuccess: "succesfully deleted row",
                              onFailure: "failed to delete row",},
  "send-row-edit-request": {onSuccess: "succesfully edited row",
                            onFailure: "failed to edit row",},
  "start-row-edit": {onSuccess: "succesfully started row editing",
                     onFailure: "failed to start row editing",},
  "cancel-row-edit": {onSuccess: "succesfully canceled row editing",
                      onFailure: "failed to cancel row editing",},
} as const satisfies Record<TableppAction['type'], Record<'onSuccess' | 'onFailure', string>>
