import React from 'react'
import PublishUtils from '../../../helpers/PublishUtils'

export const configOptions = [
  {
    name: 'query',
    type: 'yasgui',
    label: 'Query where all possible internal sources for the video queried as `?src_URL`s. External urls are not supported, the urls need to start with "/servlets/cgi/download/"',
  },
  {
    name: 'timeJumpTopic',
    type: 'text',
    label: 'Topic (variable) for which integer updates will make the video seek to that point in time as seconds',
  },
  PublishUtils.getTimestampParametersDef(),
]

export const videoPlayerDescription = {
  name: 'VideoPlayer',
  component: React.lazy(() => import('./Widget')),
  label: 'VideoPlayer',
  variables: configOptions,
}
