import { combineReducers } from 'redux'
import event from './event'
import project from './project'
import projects from './projects'
import users from './users'
import pubsub from './pubsub'
import currentViewer from './current-viewer'
import login from './login'
import debugging from './debugging'

export default combineReducers({
  event,
  project,
  projects,
  users,
  pubsub,
  login,
  currentViewer,
  debugging,
})

