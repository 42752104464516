import React from 'react'

export const configOptions = [
  {
    name: 'query',
    type: 'yasgui',
    label: 'Query where all possible sources for the video queried as `?src_URL`s',
  },
  {
    name: 'textLoading',
    type: 'text',
    label: 'text when loading',
  },
  {
    name: 'textNoResults',
    type: 'text',
    label: 'text when no results',
  },
  {
    name: 'columnCount',
    type: 'text',
    label: 'number of columns',
  },
  {
    name: 'textErrorLoading',
    type: 'text',
    label: 'text when error loading results',
  },
  {
    name: 'deletionRule',
    type: 'text',
    label: 'rule triggered when requesting delete',
  },
  {
    name: 'deletionRuleParamsJSON',
    type: 'json-text',
    label: 'parameters for the rulechain, for example {"graph":"{{graph}}"}',
  },
]

export const imageGalleryWidgetDescription = {
  name: 'Image Gallery',
  component: React.lazy(() => import('./ImageGallery')),
  label: 'Image Gallery [Temporary]',
  variables: configOptions,
}
