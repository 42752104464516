import PublishUtils from "../../../helpers/PublishUtils"
import { ConfigurablePropsFromDescription } from "../../../helpers/widget"
import { v4 as uuidv4 } from "uuid"
import { TableppAction, TableppColumnConfig } from "./config-schema"

const defaultQuery = `SELECT
  ?title
  (?note as ?title_URI)
  ("" as ?comments)
  ("fa-comments" as ?comments_ICON)
  (?note as ?comments_URI)
  ?author
  ?author_ICON
  ("16px" as ?author_ICONSIZE)
  ?author_ICONCOLOR
  ?sourceLink
  ?sourceLink_ICON
  ?sourceLink_URL
WHERE {
  BIND(IF(BOUND(?sourceLink_URL), "radix-external-link", "") AS ?sourceLink_ICON)
  VALUES (?note ?title ?author ?author_ICON ?author_ICONCOLOR ?sourceLink ?sourceLink_URL) {
    (<https://example.com/notes/1> "My first babby note" "Baby" "fa-baby" "magenta" undef undef)
    (<https://example.com/notes/2> "Server is down" "BimBamBoom" "fa-user-group" "#060270" "app.wistor.nl" "https://app.wistor.nl")
    (<https://example.com/notes/3> "Tips on keeping data secure" "Hackermann 😎" "fa-user" "#388B17" "google.com" "https://google.com")
  }
}
`

type ColumnButton = {
  icon?: string
  label?: string
  actions?: Array<TableppAction>
  buttons?: ColumnButton[]
}

export type ColumnDescription = {
  id: string
  label: string
  filterable?: boolean
  sortable?: boolean
  hidden?: boolean
  publishTopic?: string
  buttons?: ColumnButton[]
  width?: string
}

export const configOptions = [
  {
    name: 'query',
    type: 'yasgui',
    description:"extra documentation",
    defaultQuery,
    label: [
      'Query-variable-names structured as "?{identifier}_{modifier}" modify',
      'the display query-variable-results of "?{identifier}",',
      'see help for more info',
    ].join(' '),
    helpComponent: QueryHelp
  },
  {
    name: 'custom',
    type: 'tablepp-custom',
    label: 'Column Config\nℹ️Note: The displayed order of columns is determined by the order of the tabs, not the order of the result variables in the SPARQL query. You can drag-and-drop tabs to change their order.',
    helpComponent: ConfigJsonHelp
  },
  {
    name: 'publishVariable',
    type: 'text',
    label: 'Topic (variable) in which item URI is published when a link in the table is clicked. Can be overridden by the column-configuration\'s "publishTopic".',
    description: "more information about the publish variable"
  },
  {
    name: 'hideColumnHeaders',
    type: 'boolean',
    label: 'hide column headers',
  },
  {
    name: 'showFilters',
    type: 'boolean',
    label: 'enable global filter',
  },
  {
    name: 'idToFilterTextOf',
    type: 'text',
    label: 'Limit global filtering to specific identifier(s), separate with comma. Leave empty to filter all fields.',
  },
  {
    name: 'paginationEnabled',
    type: 'boolean',
    label: 'enable pagination',
  },
  {
    name: 'paginationPageSize',
    type: 'number',
    label: 'maximum number of rows to show per page',
  },
  {
    name: 'selectionMode',
    type: 'select',
    options: [
      { value: "DISABLED", label: "Disabled"},
      { value: "SINGLE", label: "Single row (highlight)"},
      { value: "MULTIPLE", label: "Multiple rows (checkboxes)"},
    ],
    label: 'row selection mode',
  },
  {
    name: 'variableToPublishOnSelection',
    type: 'text',
    label: 'Variable name from the SPARQL query representing the value for selection. Will be used for the selection publish and subscription settings below.'
  },
  {
    name: 'selectedRowSubscription',
    type: 'text',
    label: 'Variabele to listen to for row selection.',
  },
  {
    name: 'lastSelectedRowTopic',
    type: 'text',
    label: 'Topic to publish last selected row to.'
  },
  {
    name: 'selectedRowsTopic',
    type: 'text',
    label: 'Topic to publish selected rows to.'
  },
  {
    name: 'topicToListenToForSelectedRows',
    type: 'text',
    label: 'Topic to listen for selected rows, this is based on your multiple value configuration. The default format is with diamond brackets and with a comma separator, so for example: <value1>,<value2>'
  },

  PublishUtils.getMultipleValueFormatOptions(),
  {
    name: 'enableButtonExportSelectionToCsv',
    type: 'boolean',
    label: 'enable button to export selection to CSV'
  },
  {
    name: 'labelButtonExportSelectionToCSV',
    type: 'text',
    label: 'label for button to export selection to CSV'
  },
  {
    name: 'delimiterExportSelectionToCSV',
    type: 'select',
    label: 'delimiter used when exporting selection to CSV',
    options: [
      {label: 'comma', value: ','},
      {label: 'semicolon', value: ';'},
      {label: 'tab', value: '\t'}
    ]
  },
  {
    name: 'filenameExportSelectionToCSV',
    type: 'text',
    label: 'filename of csv-file when exporting selection to CSV',
    topicValueInterpolationEnabled: true
  }
] as const

function QueryHelp() {
  return (
    <div>
      <p className="mb-3">
        The results of this query are displayed in the table in the same order
        as they are returned from the triplestore.
      </p>
      <p className="mb-3">
        It is possible to modify a cell by selecting sparql-variables with the
        following name-shape <code>{'?{identifier}_{modifier}'}</code>.
        Rows have an empty identifier, e.g. <code>{'?_{modifier}'}</code>.
      </p>
      <p>
         The following <code>{'modifier'}</code>s have been implemented:
      </p>
      <ul className="list-disc pl-4">
        <li>
          <code>URI</code>: makes the cell clickable. On click it publishes this value to the global "publish topic",
          or the column-configuration-specific `publishTopic` (the latter overrides the former).{" "}
          <em>If this modifier is used, then <code>URL</code> should not be used</em> as you cannot have both behaviours.{" "}
          An error-notification is clearly visible on your screen if both modifiers are active.
        </li>
        <li>
          <code>URL</code>: makes the cell clickable. On click it opens a new browser-tab/window with this value as its URL.{" "}
          <em>If this modifier is used, then <code>URL</code> should not be used</em> as you cannot have both behaviours.{" "}
          An error-notification is clearly visible on your screen if both modifiers are active.
        </li>
        <li>
          <code>ICON</code>: name of the icon, this can be an icon from{" "}
          <a href="https://fontawesome.com/search?o=r&m=free" target="_blank" rel="noreferrer"
             className="text-indigo-400 hover:text-decoration-underline">fontawesome</a>, {" "}
          <a href="https://www.radix-ui.com/icons" target="_blank" rel="noreferrer"
             className="text-indigo-400 hover:text-decoration-underline">Radix Icons</a>, or{" "}
          <a href="https://mui.com/material-ui/material-icons/" target="_blank" rel="noreferrer"
             className="text-indigo-400 hover:text-decoration-underline">MUI Icons</a>
          <br />
          Examples: <code>fa-user</code>, <code>fa-minus-circle</code>, <code>radix-layers</code>, <code>radix-dots-horizontal</code>, <code>mui-add</code>.
        </li>
        <li>
          <code>ICONCOLOR</code>: css color of the icon. Examples: <code>blue</code>, <code>rgba(128, 128, 0, 0.5)</code>, <code>#ffee33</code>
        </li>
        <li>
          <code>ICONSIZE</code>: css size of the icon. Examples: <code>15px</code>, <code>16pt</code>, <code>2rem</code>
        </li>
        <li>
          <code>TEXTCOLOR</code>: css color of the text. Examples: <code>blue</code>, <code>rgba(128, 128, 0, 0.5)</code>, <code>#ffee33</code>
        </li>
      </ul>
    </div>
  )
}

export type TablePlusPlusConfigOptions = ConfigurablePropsFromDescription<typeof configOptions>

function ConfigJsonHelp() {
  const example: TableppColumnConfig = {
    "id": uuidv4(),
    "label": "Actions",
    "width": "150px",
    "buttons": [
      {
        "icon": "fa-star",
        "actions": [
          {
            "type": "start-rule",
            "startRule": "favourite rule",
            "parameters": {
              "someParameter": "{{someValue}}"
            },
            "timestampVariable": "myTimestamp"
          }
        ]
      },
      {
        "buttons": [
          {
            "label": "Open user details",
            "icon": "fa-user",
            "actions": [
              {
                "type": "publish",
                "topic": "userForPopup",
                "value": "{{user}}"
              }
            ]
          }
        ]
      }
    ]
  }

  return (
    <div>
      <h3 className="font-bold font-lg">SPARQL columns</h3>
      <p className="mb-3">
        The visible columns and the order of columns are explicitly decided by the SPARQL column tabs.
        Use the 'Auto fill' button to automatically populate them based on variables in
        your <code>SELECT</code> statement.
      </p>
      <p className="mb-3">
        If you see a column with the '🔗' symbol, it means it is linked to a valid SPARQL variable. <br />
        Columns with the '⛓️‍💥' symbol are not linked to a valid SPARQL variable.
      </p>
      <h3 className="font-bold font-lg">Action columns</h3>
      <p className="mb-3">
        You can also create additional columns which are not linked to a specific SPARQL variable,
        but can be used to trigger an action.

        <ul className="list-disc list-inside">
          <li>Currently two action types are supported in buttons: <code>"publish"</code> and <code>"start-rule"</code></li>
          <li>You can use query result variables in <code>{'{{placeholders}}'}</code>, from any row's SPARQL column variable or global pubsub variables</li>
          <li>Create a button without an icon and with button-children to display a "..."-dropdown menu</li>
        </ul>
      </p>
      <p className="mb-3">
        An advanced example for an action column:<br />
        <pre style={{ color: '#e83e8c' }}>{JSON.stringify(example, null, 4)}</pre>
      </p>
    </div>
  )
}