export function mapRecordToList
  <R extends Record<any,any>, K extends keyof R, V extends R[K], MappedValue>
  (record: R, map: (key: K, value: V) => MappedValue)
{
  const list = []
  for (const key in record) {
    const value = record[key]
    const mappedValue = map(key as unknown as K, value)
    list.push(mappedValue)
  }
  return list
}

export function forEachEntry
  <R extends Record<any, any>, K extends keyof R, V extends R[K]>
  (record: R, fn: (key: K, value: V) => void)
{
  for (const key in record) {
    const value = record[key]
    fn(key as unknown as K, value)
  }
}