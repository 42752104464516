import { Fragment } from 'react'

const emailRegExp =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g

export function AutoHyperlink(props) {
  if (!props.text) return null

  const emails = extractEmails(props.text)
  const texts = extractNonEmailTexts(props.text, emails)

  return (
    <span>
      {texts.map((text, i) => {
        const email = emails?.[i]
        return (
          <Fragment key={`fragment ${i}`}>
            {text}
            {email && (
              <a key={`email ${i}`} href={`mailto:${email}`}>
                {email}
              </a>
            )}
          </Fragment>
        )
      })}
    </span>
  )
}

function extractEmails(text) {
  return [...text.matchAll(emailRegExp)].map(([email]) => email)
}

function extractNonEmailTexts(text, emailsFromLeftToRight) {
  return emailsFromLeftToRight.reduceRight(
    ([text, ...texts], email) => [...text.split(email), ...texts],
    [text]
  )
}
