import * as React from 'react'
import {configOptions} from './config-options'


export const TablePlusPlusDescription = {
  name: 'Table++',
  component: React.lazy(() => import('./TablePlusPlus' )),
  label: 'Table++',
  variables: configOptions,
}
