export const DEFAULT_WIDGET_CONFIG = {
  general: {
    voteForUserActionsDisabledWhenLoading: true,
    syncWithUserActionsDisabled: true,
    showLoadingOverlay: true
  },
  SelectBox: {
    preSelectFirstItem: true
  },
  ToggleWidget: {
    appearance: 'icon',
    publishTopic: 'defaultTopic',
    enabledBgColor: '#00589b',
    disabledBgColor: 'gray'
  },
  "BB ButtonPopup": {
    popupMinWidth: '600px',
    popupMaxWidth: '100vw',
    popupMinHeight: '0',
    popupMaxHeight: '100vh',
    popupCloseOnBackdropClick: true,
    popupCloseOnEscapeKey: true
  },
  BBParameterLinkEmptyWidget: {
    showLoadingOverlay: false
  },
  JobStatus: {
    statusLabels: {
      "RUNNING": "running",
      "QUEUED": "queued"
    },
    statusTextTemplateString: "<b>{{.count}}</b> {{.status}}",
    refetchInterval: "5"
  },
  IFCJSViewerV3: {
    resetSelectionOnSparqlUpdate: true
  }
} as const
