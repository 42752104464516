import { lazy } from 'react';
export default class BBMJOPWidgetDescriptor{  

static getDescription() {
    return {
      name: 'BBMJOPwidget',
      component: lazy(() =>  import('./BBMJOPWidget.js')),
      label: 'MJOP Widget (experimental)',
      type:"experimental",
      variables: [
       
        {
          name: 'query',
          type: 'yasgui',
          label: 'use parameter... (not yet implemented)  ',
        },
       
       
              ],
    }
  }
}
